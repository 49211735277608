<template>
	<el-dialog title="二维码" :visible.sync="centerDialogVisible" :close-on-click-modal="false" width="576px" append-to-body top="40vh" center>
		<div class="code-list" v-if="qrCodeUrl">
			<div id="code-item">
				<vue-qr :text="qrCodeUrl" :size="130" :logoSrc="logo" :logoScale="0.2"> </vue-qr>
				<p>{{ name }}</p>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="success" v-loading.fullscreen.lock="fullscreenLoading" @click="screen">下载二维码</el-button>
			<el-button type="primary" @click="centerDialogVisible = false">关 闭</el-button>
		</span>
	</el-dialog>
</template>
<script>
import html2canvas from 'html2canvas';
import VueQr from 'vue-qr';
export default {
	components: {
		VueQr,
	},
	data() {
		return {
			logo: '',
			centerDialogVisible: false,
			qrCodeUrl: null,
			fullscreenLoading: false,
			name: '',
		};
	},
	watch: {},
	computed: {},
	methods: {
		screen() {
			this.fullscreenLoading = true;
			//对指定容器进行截屏
			html2canvas(document.getElementById('code-item'), {
				useCORS: true, //是否尝试使用CORS从服务器加载图像
				backgroundColor: '#fff', //画布背景色（如果未在DOM中指定）。设置null为透明
			}).then((canvas) => {
				// 返回一个 canvas 对象，在dom中渲染 canvas 对象
				//document.body.appendChild(canvas);
				// 转化成 dataurL
				let canvasImg = canvas.toDataURL('image/png');
				//调用下载方法
				this.downLoadFile('二维码', canvasImg);
				this.fullscreenLoading = false;
			});
		},
		downLoadFile(fileName, canvasImg) {
			//创建一个a标签
			var a = document.createElement('a');
			//指定下载文件名称
			a.href = canvasImg;
			a.download = fileName;
			//模拟点击
			a.click();
		},
	},
};
</script>
<style lang="scss" scoped>
.code-list {
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		text-align: center;
	}
}
::v-deep .el-dialog__body {
	text-align: initial;
	padding: 20px 40px 16px;
}
</style>